/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://bbp1x2v8y8.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "nurseReport",
            "endpoint": "https://2a614154m9.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://mscghkm4d5afvkku334cb2m2oi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ju5vzqcs5fcdjoii5p7kitsy6m",
    "aws_cognito_identity_pool_id": "us-east-1:6f637700-1453-4487-b478-3b8696d2679d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_nnNCUihi5",
    "aws_user_pools_web_client_id": "hpo6kesblasnmqu0r4a15ahrn",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "bedside-20200204102939-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://bedside-20200204102939-hostingbucket-dev.s3-website-us-east-1.amazonaws.com",
    "aws_user_files_s3_bucket": "bedsidecontent102822-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
